<template>
    <div class="wrapper_m">

        <Header1></Header1>
        <Header></Header>

        <!-- banner图 -->
        <div class="banner" :style="{backgroundImage: 'url(https://linwu.oss-cn-shanghai.aliyuncs.com/audio/imgs%20%28314%29.jpg)', height: getHeight()}">
            <div class="wrap" :style="{height: getHeight()}">
                <div class="word-wrapper">
                <span class="title"></span> <br>
                </div>
            </div>
        </div>

        <!-- 列表 -->
        <div class="music_wrapper">
            <div v-for="(item, i) in quku" :key="i" class="one_music" @click="choose_m(item)" :class="{active:item.id==link}">
                <span>{{item.mname}}</span>
                <span></span>
                <span>{{item.uname}}</span>
                <span>{{item.addTime}}</span>
            </div>
        </div>

        <!-- 播放控件 -->
        <audio :src="play_url" @ended="handleNext()"></audio>
        <div class="waikuang" title="" :style="{bottom:getStyle}">
            <div class="zhutu" title="">
                <img class="picture"  data-index="0" alt="" src="https://linwu.oss-cn-shanghai.aliyuncs.com/audio/wei.jpg">
            </div>
            <div class="m_name">{{play_name}} - {{play_uname}}</div>
            <div class="jindutiao" title="">
                <span><meter id="leimo" value="0" min="0" max="100"></meter></span>
                <br/>
                <span id="ntime" class="shijian">00:00 </span> 
                <span class="shijian"> / </span>
                <span id="ltime" class="shijian"> 00:00</span>
            </div>
            <div class="anniu" title="按钮部分">
                <!-- <button title="播放方式" @click="bffs()" id="reorder" class="one"><i id="danqu" class="fa fa-reorder"></i></button> -->
                <button class="kongjian" title="上一首" @click="last()"   id="backward"><i class="fa fa-step-backward"></i></button>
                <button class="kongjian" title="播放" @click="play()"   id="play" ><i id="played" class="fa fa-play"></i></button>
                <button class="kongjian" title="下一首" @click="next()"   id="forward"><i class="fa fa-step-forward"></i></button>
                <button class="kongjian" title="静音" @click="jingy()" id="volume"><i id="vol" class="fa fa-volume-up"></i></button>
            </div>
        </div>

        <!-- 底部 -->
        <div class="flooter">

        </div>

        <Version></Version>
        <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
    </div>
  </template>
  
  
  <script>
    import Header1 from "../../components/Header1.vue"  ;
    import Header from "../../components/Header.vue"  ;
    import Version from "../../components/Version.vue"  ;
  export default {
    name: "Music",
    components:{Header1,Header,Version} , 
        data() {
        return {
            flay:false ,    // 播放状态
            quku:[] ,    // 曲库
            play_c:0 ,  // 当前播放的第几首歌曲  
            play_name:'-' ,  // 正在播放的歌曲名
            play_url:'' , // 当前播放歌曲的url
            play_type:1 ,  // 播放类型 1顺序，2循环，3随机
            play_uname:'穗' ,  // 歌手名字
            link:'' ,   // 选中的歌曲id

            top:'' ,  // 监听滚轮滚动方向
            showMenu2:true ,  // 向上滚动为true，向下为false
            getStyle:'0' ,  // 歌曲控件的平移距离
            visitorInfo:{
                ip: '1.1.1.1',
                area: '北京市',
                browser: 'chrome',
                os: 'windows' , 
                // osVersion: 'windows 10' ,
                // location:'' , 
                agentInfo:'' , 
                isp:'' , 
                cost:''
            }
        };  
      },
      methods: {
        getHeight(){
            return window.innerHeight + 'px' ; 
        } ,
        // 获取所有歌曲
        getAllMusics(currentPage){
            let _this = this ; 
            _this.$axios.get("/getMusics").then(res => {
                _this.quku = res.data.data.records ; 
                _this.play_name = _this.quku[0].mname ; 
                _this.play_url = _this.quku[0].murl ; 
                _this.link = _this.quku[0].id
                // console.log(_this.quku);
            }) ; 
        } ,
        // 播放
        play(){
            // console.log('播放');
            let audio = document.querySelector('audio')
            let _this = this;  
            if (!_this.flay) {
                audio.play()
                document.querySelector('#played').className ='fa fa-pause'   // 更换按钮
                _this.flay = true
                // console.log('play');
            } else {
                audio.pause()
                document.querySelector('#played').className = 'fa fa-play'  // 更换按钮
                _this.flay = false
            }
        } , 
        //拿到进度条时间

        // 上一首
        last(){
            let _this = this ; 
            console.log(_this.play_c);
            // 更改图片
            // let a = picture.getAttribute('data-index')
            // picture.setAttribute('data-index',jia(a))
            // picture.src = `https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/violet.jpg` // `图片/${jia(a)}.jpg`
            // picture.alt = quku[jia(a)]
            let audio = document.querySelector('audio')
            audio.src = `${_this.quku[_this.jian(_this.play_c)].murl}`
            _this.play_name = _this.quku[_this.jian(_this.play_c)].mname ; 
            if (_this.play_c === 0 ){
                _this.play_c = _this.quku.length -1 ; 
            }else{
                _this.play_c -= 1 ; 
            }
            // if (_this.flay) {
            //     audio.play()
            //     _this.link = _this.quku[_this.jian(_this.play_c+1)].id
            // } else {
            //     audio.pause()
            // }
            audio.play()
            _this.flay = true
            _this.link = _this.quku[_this.jian(_this.play_c+1)].id
            document.querySelector('#played').className ='fa fa-pause'
            // console.log("上一首");
        },
        // 下一首
        next(){
            let _this = this ; 
            // 更改图片
            // let a = picture.getAttribute('data-index')
            // picture.setAttribute('data-index',jia(a))
            // picture.src = `https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/violet.jpg` // `图片/${jia(a)}.jpg`
            // picture.alt = quku[jia(a)]
            let audio = document.querySelector('audio')
            audio.src = `${_this.quku[_this.jia(_this.play_c)].murl}` ; 
            _this.play_name = _this.quku[_this.jia(_this.play_c)].mname ; 
            if(_this.play_c === _this.quku.length -1){
                _this.play_c = 0 ;     
            }else{
                _this.play_c += 1 ; 
            }
            // if (_this.flay) {
            //     audio.play()
            //     _this.link = _this.quku[_this.jia(_this.play_c-1)].id
            //     console.log(_this.quku[_this.jia(_this.play_c)]);
            // } else {
            //     audio.pause()
            // }
            audio.play()
            _this.flay = true
            _this.link = _this.quku[_this.jia(_this.play_c-1)].id
            document.querySelector('#played').className ='fa fa-pause'
            // console.log("下一首");
        } , 
        //函数判断是否能加一
        jia(x){
            let _this = this ; 
            if (x < _this.quku.length - 1) {
                x++
            } else {
                x = 0
            }
            // console.log(x);
            return x
        } , 
        //函数判断是否减一
        jian(x){
            let  _this = this ;
            if (x < 1) {
                x = _this.quku.length - 1
            } else {
                x--
            }
            // console.log(x);
            return x
        } , 
        // 静音
        jingy(){
            let audio = document.querySelector('audio')
            if (audio.volume > 0) {
                document.querySelector('#vol').className = 'fa fa-volume-off' ;   // 切换图标样式
                return audio.volume = 0
            } else {
                document.querySelector('#vol').className = 'fa fa-volume-up' ; 
                return audio.volume = 1
            }
        } , 
        // 切换播放方式
        bffs(){
            let _this = this ; 
            console.log(_this.play_type);
            if (_this.play_type === 1) {  // 1顺序 2循环  3随机
                document.querySelector('#danqu').className = 'fa fa-reorder'
                _this.play_type = 2 ; 
            } else {
                document.querySelector('#danqu').className = 'fa fa-refresh'
                _this.play_type = 1 ; 
            }
        } , 
        // 播放完毕切下一首
        handleNext(){
            let _this = this ; 
            let audio = document.querySelector('audio')
            // if (_this.play_type === 1) {
            //     audio.play()
            // } else {
                audio.src = `${_this.quku[_this.jia(_this.play_c)].murl}` ; 
                _this.play_name = _this.quku[_this.jia(_this.play_c)].mname ; 
                if(_this.play_c === _this.quku.length -1){
                    _this.play_c = 0 ;     
                }else{
                    _this.play_c += 1 ; 
                }
                audio.play()
                _this.link = _this.quku[_this.jia(_this.play_c-1)].id ; ; 
            // }
        } , 
        // 选择歌曲进行播放
        choose_m(item){
            console.log(item);
            let _this = this ; 
            let audio = document.querySelector('audio')
            // let num = Math.floor(Math.random()*_this.quku.length-1);  
            _this.play_name = item.mname ; 
            _this.play_uname = item.uname ; 
            audio.src = item.murl ; 
            document.querySelector('#played').className ='fa fa-pause'   // 更换按钮
            _this.flay = true
            audio.play() ; 
            _this.link = item.id ; 
            _this.getStyle='0'

        } , 
        // 获取访客信息 并保存
        async getVisitorInfo(){
            const _this = this ; 
            await this.$axios.get("/getIpInfo").then(res => {
                // console.log(res) ; 
                let area = "" ; 
                if(res.data.data.country!== null){
                area += res.data.data.country ; 
                }
                if(res.data.data.region!==null){
                area += res.data.data.region ; 
                }
                if (res.data.data.province !== null) {
                area += res.data.data.province ; 
                }
                if (res.data.data.city !== null) {
                area += res.data.data.city ; 
                }
                // console.log(area);
                this.visitorInfo.ip = res.data.data.ip ; 
                this.visitorInfo.area = area ; 
                this.visitorInfo.os = res.data.data.os ; 
                this.visitorInfo.browser = res.data.data.browser ;
                this.visitorInfo.isp = res.data.data.isp ;  
                this.visitorInfo.agentInfo = res.data.data.agentInfo ;
                this.visitorInfo.cost = res.data.data.cost ;  
            }) ; 

            // console.log(this.visitorInfo);
            await this.$axios.post('/addVisitorData' , this.visitorInfo , {

            })
        } , 
      } , 
      created(){
        let _this = this;  
        _this.getAllMusics() ; 
        _this.getVisitorInfo() ; 
        document.title = '水水的五毛钱麦克风' ; 
        // console.log(_this.play_type);
      } , 
	// 获取浏览器滚轮
	mounted() {
		window.addEventListener('scroll', () => {
			this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset   ; 
            // 上滑或下滑的时候 直接隐藏折叠菜单和menu按钮
            this.showMenu2 ? this.getStyle='0' : this.getStyle='-500px' ; 
		})
	},
    // 监听top值的变化
    watch:{
        top(newValue,oldValue){
            // 等新值大于100的时候再做变化（优化一下）
            if(newValue > 50){
                if(newValue > oldValue){
                    this.showMenu2 = false ; 
                    // console.log('向下滚动')
                }else{
                    this.showMenu2 = true ; 
                    // console.log('向上滚动')
                }
            }
        }
    }
  };
  </script>
  
  
<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  /* background-image: url("../img/k-on1.jpeg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0;
  overflow: hidden; */
  opacity: 1;
  margin-bottom: 5rem;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgba( 0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}

@media all and (min-width:850px) {

    /* .wrapper_m{
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(#2e8f45, #b29537);
    } */
    /* 播放空间区------------------------------------------------------- */
    /*把东西放到中间去*/
    .waikuang {
        margin: 0 auto;/*水平居中*/
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        /*垂直居中*/
        /*外框加一个阴影*/
        background-color: white;
        width: 900px;
        height: 200px;
        border-radius: 35px;
        transition: all .5s ease;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2)
    }
    /*主图处理*/
    .picture {
        width: 100px;
        height: auto;
        /*把主图片变小*/
        position: relative;
        left: 38px;
        top: 35px;
        /*把图片改到左边中间*/
        border-radius: 50%;
    }
    /* m_name */
    .m_name{
        position: relative;
        left: 160px;
        top: -75px;
        width: 240px;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        color: rgb(75,174,194);
    }

    /*进度条处理*/
    .jindutiao {
        position: relative;
        left: 160px;
        top: -75px;
        width: 780px;
        font-size: 14px;
        /* background: pink; */
    }

    #leimo {
        width: 700px;
        height: 20px;
    }
    .anniu {
        position: relative;
        left: 370px;
        top: -55px;
        /* background: pink; */
        /*上面是调整位置*/
        width: 250px;
    }
    .shijian{
        color: #888;
    }
    .kongjian {
        border: none;
        padding: 14px 17px;
        text-align: center;
        display: inline-block;
        font-size: 13px;
        border-radius: 50%;
        margin-left: 15px;
        cursor: pointer;
    }
    .kongjian:hover{
        color: rgb(75,174,194);
    }
    /* 播放空间区------------------------------------------------------- */

    /* 列表区============================================================ */
    .music_wrapper{
        width: 1000px;
        /* height: 800px; */
        background-color: rgba(255, 255, 255, 0.8);
        margin: 0 auto;
        position: relative;
        top: 50px;
        border-radius: 30px;
        padding: 10px 0 20px 0;
        /* margin-bottom: 50px; */
        /* box-shadow: 20px 20px 20px #3b2626 */
    }

    .one_music{
        width: 80%;
        height: 30px;
        padding: 5px 0 5px 25px;
        border-radius: 15px;
        /* background: pink; */
        line-height: 30px;
        margin: 10px auto;
        font-size: 14px;
        transition: all .1s ease;
    }

    .one_music:hover{
        transition: all .1s ease;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.6);
    }

    .one_music > span{
        display: inline-block;
        width: 200px;
    }

    .active{
        background: rgba(255, 255, 255, 0.6);
        color: rgb(75,174,194);
    }

    /* 列表区============================================================ */
    .flooter{
        width: 100%;
        height: 20px;
        /* background: pink; */
        margin-top: 200px;
    }
}


@media all and (max-width:850px) {

    /* .wrapper_m{
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(#2e8f45, #b29537);
    } */
    /* 播放空间区------------------------------------------------------- */
    /*把东西放到中间去*/
    .waikuang {
        margin: 0 auto;/*水平居中*/
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        /*垂直居中*/
        /*外框加一个阴影*/
        background-color: white;
        width: 90vw;
        height: 40vw;
        border-radius: 20px;
        transition: all .5s ease;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4)
    }
    /*主图处理*/
    .picture {
        width: 20vw;
        height: auto;
        /*把主图片变小*/
        position: relative;
        left: 5vw;
        top: 5vw;
        /*把图片改到左边中间*/
        border-radius: 50%;
    }
    /* m_name */
    .m_name{
        position: relative;
        left: 30vw;
        top: -15vw;
        width: 50vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        color: rgb(75,174,194);
    }

    /*进度条处理*/
    .jindutiao {
        position: relative;
        left: 30vw;
        top: -15vw;
        width: 55vw;
        font-size: 14px;
        /* background: pink; */
    }

    #leimo {
        width: 50vw;
        height: 20px;
    }
    .anniu {
        position: relative;
        left: 25vw;
        top: -12vw;
        /* background: pink; */
        /*上面是调整位置*/
        width: 250px;
    }
    .shijian{
        color: #888;
    }
    .kongjian {
        border: none;
        padding: 10px 14px;
        text-align: center;
        display: inline-block;
        font-size: 13px;
        border-radius: 50%;
        margin-left: 15px;
        cursor: pointer;
    }
    .kongjian:hover{
        color: rgb(75,174,194);
    }
    /* 播放空间区------------------------------------------------------- */

    /* 列表区============================================================ */
    .music_wrapper{
        width: 96vw;
        /* height: 800px; */
        background-color: rgba(255, 255, 255, 0.8);
        margin: 0 auto;
        position: relative;
        top: 20px;
        border-radius: 20px;
        padding: 10px 0 20px 0;
        /* margin-bottom: 50px; */
        /* box-shadow: 20px 20px 20px #3b2626 */
    }

    .one_music{
        width: 90%;
        height: 30px;
        padding: 5px 0 0 25px;
        border-radius: 15px;
        /* background: pink; */
        line-height: 30px;
        margin: 10px auto;
        font-size: 14px;
    }

    .one_music:hover{
        cursor: pointer;
        background: rgba(255, 255, 255, 0.6);
    }

    .one_music > span{
        display: inline-block;
        /* margin-right: 20px; */
        /* width: 200px; */
    }
    .one_music span:first-child{
        width: 45%;
        white-space: nowrap;
        /* overflow: hidden; */
        text-overflow: ellipsis;
    }
    .one_music span:nth-child(2){
        width: 10%;
    }
    .one_music span:nth-child(3){
        width: 15%;
    }
    .active{
        background: rgba(255, 255, 255, 0.6);
        color: rgb(75,174,194);
    }

    /* 列表区============================================================ */

    .flooter{
        width: 96vw;
        height: 20px;
        /* background: pink; */
        margin-top: 150px;
    }
}
</style>